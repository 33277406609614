import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  useToast,
} from "@chakra-ui/react";
import { saveQuery, updateQuery } from "../services/form-api-service";
import { useUser } from "../UserContext";

const SaveQueryModal = ({
  isOpen,
  onClose,
  activeFilters,
  onSaveSuccess,
  queryToEdit = null, // Existing query to edit
}) => {
  const user = useUser();
  const [queryName, setQueryName] = useState("");
  const toast = useToast();
  const queryId = queryToEdit?.id; // Access the query ID

  // Pre-fill query name if editing an existing query
  useEffect(() => {
    if (queryToEdit) {
      setQueryName(queryToEdit.name);
    } else {
      setQueryName("");
    }
  }, [queryToEdit]);

  const handleSaveQuery = async () => {
    if (!queryName.trim()) {
      toast({
        title: "Query name required.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const queryPayload = {
      name: queryName.trim(),
      filters: activeFilters,
      createdBy: user.session.userSub, // Replace with actual user context
    };

    try {
      if (queryToEdit?.id) {
        // Update existing query
        await updateQuery(queryId, queryPayload);
        toast({
          title: "Query updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Save new query
        const savedQuery = await saveQuery(queryPayload);
        toast({
          title: "Query saved successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        queryPayload.id = savedQuery.id; // Assign returned id if applicable
      }

      onSaveSuccess(queryPayload); // Notify parent component
      setQueryName(""); // Reset the input
      onClose();
    } catch (error) {
      toast({
        title: "Failed to save query.",
        description: error.message || "An error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{queryToEdit ? "Edit Query" : "Save Query"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Enter query name"
            value={queryName}
            onChange={(e) => setQueryName(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSaveQuery}>
            {queryToEdit ? "Update" : "Save"}
          </Button>
          <Button variant="ghost" onClick={onClose} ml={3}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SaveQueryModal;
