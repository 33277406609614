import compact from "lodash/compact";
import isArray from "lodash/isArray";
import { buildFilter } from "../filterConfig";
import * as rules from "./filterRules";

/**
 * Apply a single filter to a set of patients
 * @param {*} patients
 * @param {*} filter
 */
export function applyFilter(patients, filter) {
  return patients.filter((patient) => {
    console.log("Applying filter:", filter, "on patient:", patient);
    const filterFn = rules[filter.rule.toString()];
    if (!filterFn) {
      console.error(`No filter function found for rule: ${filter.rule}`);
      return false;
    }
    // Handle filters with different structures
    if (filter.value && typeof filter.value === "object") {
      // For complex filters like dateInRange
      return filterFn(patient, { property: filter.property, ...filter.value });
    } else {
      // For simple filters like valueEquals or valueInRange
      return filterFn(patient, {
        property: filter.property,
        value: filter.value,
      });
    }
  });
}

/**
 * Apply multiple filters, one after the other
 * @param {*} patients
 * @param {*} filters
 */
export function applyFilters(patients, filters = []) {
  if (!isArray(filters)) filters = [filters];

  return compact(filters).reduce(
    (acc, filter) => [...applyFilter(acc, filter)],
    [...patients]
  );
}

// builds an array of filters based on the users current filter options.
export function filtersFromUserOptions(state) {
  console.log("filtersFromUserOptions state", state);
  //  const { filterOptions  = {}, ...otherState } = state;
  const filterOptions = state;
  console.log("filtersFromUserOptions input:", filterOptions);

  // Destructure filterOptions with defaults
  const { activeFilters = [], nameFilter } = filterOptions;

  // Build initial filters based on the current "activeFilters"
  const filters = activeFilters.map((filterRule) => {
    const ruleKey =
      typeof filterRule === "string"
        ? filterRule
        : filterRule.key || filterRule.name;
    console.log(
      "Building filter for ruleKey:",
      ruleKey,
      "with these filterOptions",
      filterOptions
    );

    const buildFilterFn = buildFilter(ruleKey);
    console.log("FilterRule:", filterRule);

    // Adapt the filterRule to match the expected structure for `buildFilterFn`
    let adaptedFilterRule;
    if (filterRule.value !== undefined) {
      // If `filterRule.value` is an object (e.g., { min, max }), pass it as is
      if (typeof filterRule.value === "object") {
        adaptedFilterRule = { ...filterRule.value, key: ruleKey };
      } else {
        // Otherwise, treat `filterRule.value` as the actual value (e.g., 'F')
        adaptedFilterRule = { value: filterRule.value, key: ruleKey };
      }
    } else {
      // If no `value` key, use the entire `filterRule` as is
      adaptedFilterRule = filterRule;
    }

    console.log("Adapted FilterRule:", adaptedFilterRule);

    // Build the filter using the adapted structure
    const filter = buildFilterFn(adaptedFilterRule);
    console.log("Generated Filter:", filter);
    return filter;
  });

  // Add in the special "Name filter", which is a filter but not in the
  // typical sense (so, it's not on the menu for example)
  if (filterOptions.nameFilter) {
    filters.push({
      rule: "nameIncludes",
      value: filterOptions.nameFilter,
    });
  }
  console.log("filtersFromUserOptions output:", filters);

  return filters;
}
