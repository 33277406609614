import { createSelector } from "reselect";
import { get, uniq, compact } from "lodash";

import {
  applyFilters,
  applyFilter,
  filtersFromUserOptions,
} from "../utils/filter";

// TODO: Use these in the `mapStateToProps` functions accross application
// rather than accessing state directly?
const allPatientsSelector = (state) => state.db.patients;
export const allGlassesSelector = (state) => state.db.glasses;
// const barSelector = (state) => state.bar;
const favouritesSelector = (state) => state.favourites;

const currentSlugFromUrlSelector = (_, props) =>
  get(props, "match.params.slug");

const currentSlugFromPatientPropSelector = (_, props) =>
  get(props, "patient.slug");

// figures out the patient in question from either the page URL
// or, if that doesn't exist, it looks for a "patient" prop and
// gets the slug from that.
const currentSlugSelector = createSelector(
  currentSlugFromUrlSelector,
  currentSlugFromPatientPropSelector,
  (urlSlug, PatientPropSlug) => urlSlug || PatientPropSlug
);

// isFavouriteSelector
// Derives whether the current patient is a favourite
export const isFavouriteSelector = createSelector(
  favouritesSelector,
  currentSlugSelector,
  (favourites, patientSlug) => favourites.includes(patientSlug)
);

export const currentPatientSelector = createSelector(
  allPatientsSelector,
  currentSlugSelector,
  (patients, slug) => patients.find((c) => c.slug === slug)
);

// queryFiltersSelector
export const queryFilterOptionsSelector = (state) => state.queryFilterOptions;

export const filteredSeriesSelector = createSelector(
  [allPatientsSelector, queryFilterOptionsSelector],
  (patients, queryFilterOptions) => {
    return patients.filter((patient) => {
      // Apply filters based on patient demographics, study metadata, etc.
      return (
        (!queryFilterOptions.PatientName ||
          patient.PatientName.includes(queryFilterOptions.PatientName)) &&
        (!queryFilterOptions.Measurements ||
          queryFilterOptions.Measurements.every((measurement) =>
            patient.Measurements?.includes(measurement)
          )) &&
        (!queryFilterOptions.ClinicalOutcomes ||
          queryFilterOptions.ClinicalOutcomes.every((outcome) =>
            patient.ClinicalOutcomes?.includes(outcome)
          )) &&
        (!queryFilterOptions.QualityMetrics ||
          patient.QualityMetrics >= queryFilterOptions.QualityMetrics)
      );
    });
  }
);

// filtersSelector
// Derives the currently applied filters
// const filtersSelector = (state) => filtersFromUserOptions(state);

// filteredPatientsSelector
// Derives the currently filtered patients
/*export const filteredPatientsSelector = createSelector(
  allPatientsSelector,
  filtersSelector,
  (patients, filter) => applyFilters(patients, filter)  
  // Sorting based on abnTotal has been disabled.
  // Replaced with reverse chronological sorting.
  // .sort((a, b) =>
  //   a.abnTotal > b.abnTotal ? 1 : -1
  // )
);
*/

export const filtersSelector = createSelector(
  (state) => {
    console.log("State in filtersSelector:", state); // Log entire state
    return state.filterOptions;
  },
  (filterOptions) => {
    console.log("Filters received in filtersSelector:", filterOptions); // Debugging log
    return filtersFromUserOptions(filterOptions || {}); // Handle undefined or null
  }
);

export const filteredPatientsSelector = createSelector(
  allPatientsSelector,
  filtersSelector,
  (patients, filters) => {
    console.log("Patients Input:", patients);
    console.log("Filters Input:", filters);
    const filtered = applyFilters(patients, filters);
    console.log("Filtered Patients:", filtered);
    return filtered;
  }
);

/*
// makeableCocktailsSelector
// Derives the currently makeable cocktails based on bar contents
export const makeableCocktailsSelector = createSelector(
  allPatientsSelector,
  barSelector,
  (cocktails, bar) =>
    applyFilter(cocktails, {
      rule: "makeableFrom",
      ingredients: bar,
    })
);
*/

// allCategoriesSelector
// Derives an array of all the categories
export const allCategoriesSelector = createSelector(
  allPatientsSelector,
  (patients) => compact(uniq(patients.map((c) => c.category)))
);
