import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  useToast,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  getAllQueries,
  deleteQuery,
  updateQuery,
} from "../services/form-api-service";
import SaveQueryModal from "./SaveQueryModal";
import { isEqual } from "lodash"; // Import lodash for deep comparison

const SavedQueriesLibrary = ({
  onLoadQueries,
  activeFilters,
  setLoadedQuery,
  loadedQuery,
}) => {
  const [savedQueries, setSavedQueries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [queryToEdit, setQueryToEdit] = useState(null);
  const [modifiedFilters, setModifiedFilters] = useState(null); // Store the modified filters
  const toast = useToast();

  // Fetch saved queries on component mount
  useEffect(() => {
    const fetchQueries = async () => {
      try {
        const queries = await getAllQueries();
        setSavedQueries(queries);
      } catch (error) {
        toast({
          title: "Failed to load queries.",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchQueries();
  }, [toast]);

  const handleDeleteQuery = async (queryId) => {
    try {
      await deleteQuery(queryId);
      setSavedQueries((prev) => prev.filter((q) => q._id !== queryId));
      toast({
        title: "Query deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Failed to delete query.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleLoadQueries = (filters, query) => {
    setLoadedQuery(query); // Set the loaded query
    onLoadQueries(filters);
    setModifiedFilters(filters); // Initialize modifiedFilters with the loaded query's filters
  };

  const handleUpdateQuery = async () => {
    if (loadedQuery && modifiedFilters) {
      try {
        const completeFilters = activeFilters || loadedQuery.filters; // Fallback to loaded query filters if no active changes
        const updatedQuery = { ...loadedQuery, filters: completeFilters };
        const result = await updateQuery(updatedQuery, loadedQuery._id);
        console.log("updateQuery", result);

        // Update the saved queries list
        setSavedQueries((prev) =>
          prev.map((q) => (q._id === result._id ? result : q))
        );

        toast({
          title: "Query updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setLoadedQuery(result); // Reset loadedQuery to the updated version
      } catch (error) {
        toast({
          title: "Failed to update query.",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const isQueryModified =
    loadedQuery &&
    JSON.stringify(activeFilters) !== JSON.stringify(loadedQuery.filters);

  return (
    <Box>
      <List spacing={3}>
        {savedQueries.map((query) => (
          <ListItem
            key={query._id}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex align="center">
              <Box>
                <strong>{query.name}</strong>
              </Box>
              <Button
                size="sm"
                colorScheme="blue"
                ml={3}
                onClick={() => handleLoadQueries(query.filters, query)}
              >
                Load
              </Button>
              <Button
                size="sm"
                colorScheme="yellow"
                ml={3}
                onClick={handleUpdateQuery}
                isDisabled={!isQueryModified}
              >
                Update
              </Button>
            </Flex>
            <Flex>
              <IconButton
                size="sm"
                icon={<DeleteIcon />}
                colorScheme="red"
                onClick={() => handleDeleteQuery(query._id)}
              />
            </Flex>
          </ListItem>
        ))}
      </List>

      {/* Save Query Modal for Editing */}
      {isModalOpen && (
        <SaveQueryModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          activeFilters={queryToEdit?.filters || []}
          onSaveSuccess={(updatedQuery) => {
            setSavedQueries((prev) =>
              prev.map((q) => (q._id === updatedQuery._id ? updatedQuery : q))
            );
          }}
          queryToEdit={queryToEdit}
        />
      )}
    </Box>
  );
};

export default SavedQueriesLibrary;
