import React, { useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { debounce } from "lodash";
import pluralize from "pluralize";

import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { MdFilterList } from "react-icons/md";

import { getRules } from "../filterConfig";
import { removeOrAddItemFromArray } from "../utils/util";
import { labelFor } from "../filterConfig";
import { updateFilter, activateFilterDialog } from "../actions";

import FilterChips from "./FilterChips";

const styles = {
  patientsCount: {
    textTransform: "upperCase",
  },
  filterButton: {
    float: "right",
    margin: ".5rem",
  },
  closeButton: {
    position: "absolute",
    right: ".5rem",
    top: ".5rem",
    color: "grey",
  },
  patientCountContainer: {
    padding: ".5rem",
    fontWeight: "bold",
    fontSize: 20,
    clear: "both",
    textAlign: "center",
    textTransform: "uppercase",
    backgroundColor: "#78909c",
    color: "rgba(0, 0, 0, 0.87)",
  },
};

const PatientFilter = ({
  filteredPatients,
  filterOptions: { activeFilters, nameFilter },
  updateFilter,
  activateFilterDialog,
}) => {
  const updateNameFilter = useMemo(
    () =>
      debounce((searchText) => {
        updateFilter({
          nameFilter: searchText,
        });
      }, 100),
    [updateFilter]
  );

  function addFilter(filter) {
    updateFilter({
      activeFilters: removeOrAddItemFromArray(filter, activeFilters),
    });
    activateFilterDialog(filter);
  }

  return (
    <Flex mt=".5rem" direction="column">
      <Flex justify="space-between">
        <Flex justify="flex-start">
          <InputGroup w={{ base: "none", md: "flex" }} mb=".5rem" id="search">
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon />}
              id="searchicon"
            />
            <Input
              id="searchtext"
              variant="flushed"
              defaultValue={nameFilter}
              placeholder="Search by name"
              onChange={(e) => updateNameFilter(e?.target?.value)}
            />
          </InputGroup>

          {nameFilter && (
            <Text
              display={{ base: "none", md: "flex" }}
              alignSelf="center"
              marginLeft=".5rem"
            >
              includes Signed studies
            </Text>
          )}

          <FilterChips />
        </Flex>

        <Menu>
          <MenuButton
            as={Button}
            variant="ghost"
            leftIcon={<MdFilterList fontSize="24px" />}
          >
            ADD FILTER
          </MenuButton>
          <MenuList>
            {getRules("PatientFilter").map((menuOption, idx) => {
              return (
                <MenuItem
                  disabled={activeFilters.includes(menuOption)}
                  key={idx}
                  onClick={() => addFilter(menuOption)}
                >
                  {labelFor(menuOption)}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Flex>

      <div style={styles.patientCountContainer}>
        Showing {pluralize("study", filteredPatients.length, true)}
      </div>
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  filterOptions: state.filterOptions,
});

const mapDispatchToProps = (dispatch) => ({
  updateFilter: bindActionCreators(updateFilter, dispatch),
  activateFilterDialog: bindActionCreators(activateFilterDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientFilter);
