import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Input,
  Select,
  Button,
  Box,
  HStack,
  Text,
} from "@chakra-ui/react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DynamicFilterUI = ({ filterKey, filterConfig, onChange, resetKey }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState(
    filterConfig?.uiProps?.defaultValue || false
  );
  const [textValue, setTextValue] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");
  const [rangeValues, setRangeValues] = useState(
    filterConfig?.uiProps?.defaultValue || [
      filterConfig?.uiProps?.min || 0,
      filterConfig?.uiProps?.max || 100,
    ]
  );

  // Reset component state when resetKey changes
  useEffect(() => {
    setStartDate(new Date());
    setEndDate(null);
    setCheckboxValue(filterConfig?.uiProps?.defaultValue || false);
    setTextValue("");
    setDropdownValue("");
    setRangeValues(
      filterConfig?.uiProps?.defaultValue || [
        filterConfig?.uiProps?.min || 0,
        filterConfig?.uiProps?.max || 100,
      ]
    );
  }, [resetKey, filterConfig]);

  const renderUI = () => {
    switch (filterConfig?.uiType) {
      case "checkbox":
        return (
          <Checkbox
            isChecked={checkboxValue}
            onChange={(e) => {
              setCheckboxValue(e.target.checked);
              onChange(e.target.checked);
            }}
          >
            {filterConfig.label}
          </Checkbox>
        );

      case "text":
        return (
          <Input
            value={textValue}
            onChange={(e) => {
              setTextValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        );

      case "date":
        return (
          <DatePicker
            selected={startDate}
            onChange={(dates) => {
              const [start, end] = dates || []; // Ensure dates is always an array
              setStartDate(start || new Date());
              setEndDate(end || null);
              onChange({
                startDate: start ? start.toISOString() : null,
                endDate: end ? end.toISOString() : null,
              });
            }}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            showYearDropdown
            showMonthDropdown
            isClearable
          />
        );

      case "dropdown":
        return (
          <Select
            value={dropdownValue}
            onChange={(e) => {
              setDropdownValue(e.target.value);
              onChange(e.target.value);
            }}
          >
            <option value="" disabled>
              Select {filterConfig.label}
            </option>
            {filterConfig?.uiProps?.options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        );

      case "range":
        return (
          <Box p={4}>
            <Slider
              range
              min={filterConfig?.uiProps?.min || 0}
              max={filterConfig?.uiProps?.max || 100}
              value={rangeValues}
              onChange={(value) => {
                setRangeValues(value);
                onChange({ min: value[0], max: value[1] });
              }}
              marks={{
                [filterConfig?.uiProps?.min || 0]: `${
                  filterConfig?.uiProps?.min || 0
                }`,
                [filterConfig?.uiProps?.max || 100]: `${
                  filterConfig?.uiProps?.max || 100
                }`,
              }}
              allowCross={false}
            />
            <Box mt={2} textAlign="center">
              <Text>
                Selected: {rangeValues[0]} - {rangeValues[1]}
              </Text>
            </Box>
          </Box>
        );

      default:
        console.error(`Unsupported uiType: ${filterConfig.uiType}`);
        return null;
    }
  };

  return (
    <div>
      <label>{filterConfig.label}</label>
      {renderUI()}
    </div>
  );
};

export default DynamicFilterUI;
