import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../UserContext";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Box,
  Text,
  Flex,
} from "@chakra-ui/react";
import { calculateAge } from "../utils/util";
import { getFilterConfig } from "../filterConfig";

const ResultsTable = ({ patients, loading, activeFilters }) => {
  const user = useUser();
  const userattr = user?.attributes;
  const isClickable =
    userattr["custom:role"] === "cardiologist" ||
    userattr["custom:role"] === "sonographer";

  // Extract the filter configs to map filter names to properties
  const selectedColumns = activeFilters
    .map((filter) => {
      const config = getFilterConfig(filter.key);
      return config?.buildFilter({}).property;
    })
    .filter(
      (property) => property && !["PatientAge", "PatientSex"].includes(property)
    );

  if (loading) {
    return (
      <Flex justify="center" align="center" height="200px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (!patients || patients.length === 0) {
    return (
      <Box textAlign="center" mt={8}>
        <Text fontSize="lg" fontWeight="bold">
          No results found
        </Text>
        <Text fontSize="sm" color="gray.500">
          Try adjusting the filters to see more results.
        </Text>
      </Box>
    );
  }

  return (
    <Box overflowX="auto" mt={4}>
      <Table variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th>Patient ID</Th>
            <Th>Age</Th>
            <Th>Sex</Th>
            {selectedColumns.map((property) => (
              <Th key={property}>{property}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {patients.map((patient) => (
            <Tr key={patient._id}>
              <Td>
                {isClickable ? (
                  <Link to={`/patients/${patient.slug}`}>{patient._id}</Link>
                ) : (
                  patient._id
                )}
              </Td>
              <Td>
                {patient.PatientAge
                  ? patient.PatientAge
                  : calculateAge(patient.PatientBirthDate)}
              </Td>
              <Td>{patient.PatientSex || "N/A"}</Td>
              {selectedColumns.map((property) => (
                <Td key={property}>{patient[property] || "N/A"}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ResultsTable;
