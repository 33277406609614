import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { filteredPatientsSelector } from "../selectors";
import { RESET_FILTERS } from "../actionTypes";
import FiltersPanel from "./FiltersPanel";
import ResultsTable from "./ResultsTable";
import ResultsSummary from "./ResultsSummary";
import ScrollTopButton from "./ScrollTopButton";
import { Button } from "@chakra-ui/react";
import ExportModal from "./ExportModal";
import "./QueryTool.css";

const QueryTool = () => {
  const dispatch = useDispatch();
  const filteredPatients = useSelector(filteredPatientsSelector);
  const [loading, setLoading] = useState(false);
  const activeFilters = useSelector(
    (state) => state.filterOptions.activeFilters
  );
  const [isMobile, setIsMobile] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const resultsRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Example breakpoint for mobile
    };

    handleResize(); // Check on component mount
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Clear filters on component mount
    dispatch({ type: RESET_FILTERS });
  }, [dispatch]);

  const handleFilterChange = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000); // Simulate a delay for fetching/filtering data
  };

  const handleJumpToResults = () => {
    if (resultsRef.current) {
      resultsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleExportClick = () => {
    setIsExportModalOpen(true);
  };

  return (
    <div className={`query-tool ${isMobile ? "mobile" : "desktop"}`}>
      {/* Desktop Layout */}
      {!isMobile && (
        <div className="query-tool-container">
          <div
            className="filters-panel"
            style={{
              width: `${Math.max(
                30,
                Math.min(activeFilters.length * 10, 40)
              )}%`,
            }}
          >
            <FiltersPanel onFilterChange={handleFilterChange} />
          </div>
          <div
            className="results-section"
            style={{
              width: `${
                100 - Math.max(30, Math.min(activeFilters.length * 10, 40))
              }%`,
              minWidth: "50%",
            }}
          >
            {loading ? (
              <p>Loading results...</p>
            ) : filteredPatients.length > 0 ? (
              <>
                <div ref={resultsRef}>
                  <ResultsSummary
                    id="results-summary"
                    totalResults={filteredPatients.length}
                    onExportClick={handleExportClick}
                  />
                  <ResultsTable
                    patients={filteredPatients}
                    loading={loading}
                    activeFilters={activeFilters}
                  />
                  {isExportModalOpen && (
                    <ExportModal
                      isOpen={isExportModalOpen}
                      onClose={() => setIsExportModalOpen(false)}
                      patients={filteredPatients}
                      activeFilters={activeFilters}
                    />
                  )}
                </div>
              </>
            ) : (
              <p>No patients match the selected filters.</p>
            )}
          </div>
        </div>
      )}

      {/* Mobile Layout */}
      {isMobile && (
        <div className="query-tool-container">
          {filteredPatients.length > 0 && (
            <Button
              colorScheme="blue"
              onClick={handleJumpToResults}
              className="jump-to-results"
              mb={4}
            >
              Jump to Results
            </Button>
          )}
          <FiltersPanel onFilterChange={handleFilterChange} />
          {loading ? (
            <p>Loading results...</p>
          ) : filteredPatients.length > 0 ? (
            <>
              <div ref={resultsRef}>
                <ResultsSummary
                  totalResults={filteredPatients.length}
                  onExportClick={handleExportClick}
                  isMobile={isMobile}
                />
                <ResultsTable
                  patients={filteredPatients}
                  loading={loading}
                  activeFilters={activeFilters}
                />
              </div>
            </>
          ) : (
            <p>No patients match the selected filters.</p>
          )}
        </div>
      )}

      {/*      <ScrollTopButton /> */}
    </div>
  );
};

export default QueryTool;
