import React from "react";
import { Flex, Box, Button } from "@chakra-ui/react";

const ResultsSummary = ({ totalResults, onExportClick, isMobile }) => (
  <Flex
    className="results-summary-container"
    justify="space-between"
    align="center"
    mb={4}
  >
    <Box className="results-summary" fontWeight="bold" fontSize="lg">
      {totalResults > 0 ? `Found ${totalResults} results` : "No results found"}
    </Box>
    {totalResults > 0 && (
      <Button
        className="export-results-button"
        colorScheme="blue"
        onClick={onExportClick}
        isDisabled={isMobile}
      >
        Export Results
      </Button>
    )}
  </Flex>
);

export default ResultsSummary;
