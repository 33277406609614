import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Gravatar from "react-gravatar";

import {
  Menu as ChakraMenu,
  MenuItem,
  MenuButton,
  MenuList,
  Box,
} from "@chakra-ui/react";
import { MdHome, MdPerson, MdExitToApp, MdSearch } from "react-icons/md";
import { FaRegChartBar, FaFax, FaCloudUploadAlt } from "react-icons/fa";

import { Menu, Item, Submenu, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

import { useUser } from "../UserContext";
import { getCookieWithSuffix } from "../services/auth.service";

const ResourcesMenu = () => (
  <Menu id="resources-menu">
    <Submenu label="NEW! St. Michael's Echo Rounds">
      <Item
        onClick={() => {
          window.open(
            "https://www.youtube.com/@smhecho/videos",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        YouTube
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://utreon.us1.list-manage.com/subscribe?u=89dd5caf7d2bfdb39fa7837ae&id=57b07a84ff",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Subscribe to Rounds
      </Item>
    </Submenu>

    <Submenu label="LV">
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/RWT.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        RWT - LV Mass Index
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/LVSizeFunction.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        LV Size and Function
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/LVFunction.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        LV Function (age and gender)
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/LVDiastolic.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        LV Diastolic Dysfunction Algorithm
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/Constrictive.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Constrictive Pericarditis vs Restrictive Cardiomyopathy
      </Item>
    </Submenu>

    <Submenu label="Valvular">
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ASTable.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        AS -Table - Hemodynamic{" "}
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ASAlgorithm.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        AS - algorithm (Low Flow) - Stepwise approach
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/MVStenosis.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        MV Stenosis
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/TVStenosis.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        TV Stenosis
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/PlVStenosis.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Pl.V Stenosis
      </Item>
    </Submenu>
    <Submenu label="Prosthetic Valves">
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ProstheticAV.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Prosthetic AV algorithm (with elevated jet Vel)
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ProstheticAR.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Prosthetic AR algorithm
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ProstheticMV.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Prosthetic MV Stenosis
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ProstheticMR.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Prosthetic MR - table
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ProstheticMRAlgo.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Prosthetic MR algorithm
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ProstheticTV.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Prosthetic TV Stenosis
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/TVRepair.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        TV Repair regurgitation
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ProstheticPlV.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Prosthetic Pl.Valve Stenosis
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ProstheticPlVRegurg.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Prosthetic Pl.V regurgitation
      </Item>
    </Submenu>
    <Submenu label="Native Regurgitation">
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ChronicARAlgo.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Chronic AR algorithm
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ChronicARTable.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Chronic AR - Table
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ChronicMRAlgo.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Chronic MR algorithm
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ChronicMRTable.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Chronic MR - Table
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ChronicTRAlgo.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Chronic TR algorithm
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ChronicTRTable.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Chronic TR - Table
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ChronicPlVAlgo.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Chronic Pl.V regurgitation algorithm
      </Item>
      <Item
        onClick={() => {
          window.open(
            "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ChronicPlVTable.png",
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
        }}
      >
        Pl.V regurgitation - Table
      </Item>
    </Submenu>
  </Menu>
);

const AccountButton = ({ signOut }) => {
  const history = useHistory();
  const [newOrthancWindow, setNewOrthancWindow] = useState(null);
  const user = useUser();
  const userattr = user?.attributes || {};
  const usersession = user?.session || {};

  const logOut = () => {
    history.push("/");
    //    Cookies.remove("accesstoken", { domain: ".futurepacs.com" });
    signOut();
  };

  const handleOpenQueryTool = () => {
    history.push("/query-tool");
  };

  const { show } = useContextMenu();
  function displayMenu(e, menuid) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({
      id: menuid,
      event: e,
      props: {},
    });
  }

  const handleOpenOrthancWindow = () => {
    const win = window.open(
      `${userattr["custom:gateway"]}/orthanc/app/explorer.html#upload`,
      "_blank",
      "toolbar=0,location=0,menubar=0"
    );
    if (win) setNewOrthancWindow(win);
  };

  const handleCloseOrthancWindowAndLogout = () => {
    if (newOrthancWindow) {
      newOrthancWindow.close(); // Close the opened window
      setNewOrthancWindow(null); // Clear the window reference
    }
    logOut();
  };

  return (
    <>
      <ChakraMenu>
        <MenuButton>
          <Gravatar email={user?.email || ""} style={{ borderRadius: "50%" }} />
        </MenuButton>
        <MenuList fontSize="16px" color={"black"} zIndex={3}>
          <MenuItem as={RouterLink} to="/">
            <Box w="2.5rem" mr="1rem">
              <MdHome />
            </Box>
            Home
          </MenuItem>
          <MenuItem as={RouterLink} to="/profile">
            <Box w="2.5rem" mr="1rem">
              <MdPerson />
            </Box>
            Profile
          </MenuItem>
          <MenuItem onClick={(event) => displayMenu(event, "resources-menu")}>
            <Box w="2.5rem" mr="1rem">
              <span role="img" aria-label="paper icon">
                📄
              </span>
            </Box>
            Resources
          </MenuItem>

          {userattr["custom:role"] &&
            userattr["custom:role"] !== "external" &&
            userattr["custom:role"] !== "learner" &&
            userattr["custom:role"] !== "patient" && (
              <>
                <MenuItem
                  display={{ base: "none", md: "flex" }}
                  as={RouterLink}
                  to="/audit"
                >
                  <Box w="2.5rem" mr="1rem">
                    &#9878;
                  </Box>
                  Audit
                </MenuItem>
                <MenuItem
                  display={{ base: "none", md: "flex" }}
                  as={RouterLink}
                  to="/faxStatus"
                >
                  <Box w="2.5rem" mr="1rem">
                    <FaFax />
                  </Box>
                  Fax status
                </MenuItem>
                <MenuItem
                  display={{ base: "none", md: "flex" }}
                  onClick={() => {
                    //    addAuditData(user, patientData, "echo viewer open");

                    async function openOrthanc() {
                      var strClientAccessToken =
                        getCookieWithSuffix(".accessToken");

                      handleOpenOrthancWindow();

                      try {
                        await fetch(
                          `${userattr["custom:gateway"]}/orthanc/app/explorer.html#upload`,
                          {
                            method: "GET",
                            headers: {
                              Authorization: `Bearer ${strClientAccessToken}`, // Send token here
                            },
                            credentials: "include", // Ensure cookies from NGINX are set in the browser
                          }
                        );
                      } catch (error) {
                        console.error(
                          "Error during authorization fetch:",
                          error
                        );
                      }
                    }
                    openOrthanc();
                  }}
                >
                  <Box w="2.5rem" mr="1rem">
                    <FaCloudUploadAlt />
                  </Box>
                  Upload
                </MenuItem>
                <MenuItem
                  display={{ base: "none", md: "flex" }}
                  as={RouterLink}
                  to="/referringphysicians"
                >
                  <Box w="2.5rem" mr="1rem">
                    <span role="img" aria-label="physicians icon">
                      👩‍⚕️👨‍⚕️
                    </span>
                  </Box>
                  Referring Physicians
                </MenuItem>
                <MenuItem
                  display={{ base: "none", md: "flex" }}
                  as={RouterLink}
                  to="/reporttemplates"
                >
                  <Box w="2.5rem" mr="1rem">
                    <span role="img" aria-label="paper icon">
                      📄
                    </span>
                  </Box>
                  Report Templates
                </MenuItem>
                <MenuItem
                  display={{ base: "none", md: "flex" }}
                  as={RouterLink}
                  to="/normalvalues"
                >
                  <Box w="2.5rem" mr="1rem">
                    <FaRegChartBar />
                  </Box>
                  Normative Values
                </MenuItem>
                <MenuItem
                  display={{ base: "none", md: "flex" }}
                  as={RouterLink}
                  to="/macros"
                >
                  <Box w="2.5rem" mr="1rem">
                    <FaRegChartBar />
                  </Box>
                  Macros
                </MenuItem>
                <MenuItem as={RouterLink} to="/user-management">
                  <Box w="2.5rem" mr="1rem">
                    👥
                  </Box>
                  User Mgmt
                </MenuItem>
                <MenuItem onClick={handleOpenQueryTool}>
                  <Box w="2.5rem" mr="1rem">
                    <MdSearch />
                  </Box>
                  Query Tool
                </MenuItem>
              </>
            )}
          <MenuItem
            onClick={() => {
              handleCloseOrthancWindowAndLogout();
            }}
          >
            <Box w="2.5rem" mr="1rem">
              <MdExitToApp />
            </Box>
            Signout
          </MenuItem>
        </MenuList>
        <ResourcesMenu />
      </ChakraMenu>
    </>
  );
};

export default AccountButton;
