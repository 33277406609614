import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Flex,
  Stack,
  Tag,
  TagLabel,
  TagCloseButton,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { activateFilterDialog, updateFilter } from "../actions";
import {
  getRules,
  labelFor,
  dialogFor,
  getFilterConfig,
  filterConfig,
} from "../filterConfig";
import DynamicFilterUI from "./DynamicFilterUI";
import SaveQueryModal from "./SaveQueryModal";
import SavedQueriesLibrary from "./SavedQueriesLibrary";
import ScrollTopButton from "./ScrollTopButton";

const FiltersPanel = () => {
  const dispatch = useDispatch();
  const { activeFilters } = useSelector((state) => state.filterOptions);
  const patients = useSelector((state) => state.db.patients);
  const [resetKey, setResetKey] = useState(0);
  const [loadedQuery, setLoadedQuery] = useState(null);
  const filtersPanelRef = useRef(null); // Reference for scrolling
  const [isScrollTopVisible, setIsScrollTopVisible] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const categories = {
    demographics: "Demographics",
    clinical: "Clinical",
    imaging: "Imaging",
    treatment: "Treatment",
    metadata: "Metadata",
    audit: "Audit",
    other: "Other",
  };

  useEffect(() => {
    if (patients?.length > 0) {
      Object.keys(filterConfig).forEach((key) => {
        const config = filterConfig[key];
        if (config.uiProps?.getOptions) {
          config.uiProps.options = config.uiProps.getOptions(patients);
        }
      });
    }
  }, [patients]);

  const rules = getRules("FiltersPanel");

  const groupedFilters = rules.reduce((acc, rule) => {
    const filterConfig = getFilterConfig(rule);
    const category = filterConfig?.category || "other";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push({ rule, filterConfig });
    return acc;
  }, {});

  const handleScroll = () => {
    if (filtersPanelRef.current) {
      const { scrollTop } = filtersPanelRef.current;
      setIsScrollTopVisible(scrollTop > 100);
      console.log(
        "ScrollTop:",
        scrollTop,
        "Is Scroll Top Visible:",
        scrollTop > 100
      );
    }
  };

  useEffect(() => {
    const panel = filtersPanelRef.current;
    if (panel) {
      panel.addEventListener("scroll", handleScroll);
      return () => panel.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const handleFilterChange = (filterKey, value) => {
    const filterIndex = activeFilters.findIndex((f) => f.key === filterKey);
    if (filterIndex >= 0) {
      const updatedFilters = [...activeFilters];
      updatedFilters[filterIndex] = { key: filterKey, value };
      dispatch(updateFilter({ activeFilters: updatedFilters }));
    } else {
      dispatch(
        updateFilter({
          activeFilters: [...activeFilters, { key: filterKey, value }],
        })
      );
    }

    const dialog = dialogFor(filterKey);
    if (dialog) {
      dispatch(activateFilterDialog(dialog));
    }
  };

  const removeFilter = (filterKey) => {
    const updatedFilters = activeFilters.filter(
      (filter) => filter.key !== filterKey
    );
    dispatch(updateFilter({ activeFilters: updatedFilters }));
  };

  const handleClearAll = () => {
    dispatch(updateFilter({ activeFilters: [] }));
    setResetKey((prevKey) => prevKey + 1);
    if (filtersPanelRef.current) {
      filtersPanelRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleLoadQueries = (filters) => {
    dispatch(updateFilter({ activeFilters: filters }));
  };

  const scrollToTop = () => {
    if (filtersPanelRef.current) {
      filtersPanelRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <Box
      className="filters-panel"
      border="1px solid #ccc"
      p={4}
      borderRadius="md"
      maxHeight="80vh"
      overflowY="auto"
      position="relative"
      ref={filtersPanelRef}
    >
      <Flex justify="space-between" mb={4}>
        <Box fontWeight="bold" fontSize="lg">
          Filters
        </Box>
        <Button onClick={handleClearAll}>Clear All</Button>
      </Flex>

      {/* Active Filters */}
      <Box mb={4}>
        <Flex wrap="wrap" gap={2}>
          {activeFilters.map(({ key, value }) => (
            <Tag key={key} colorScheme="blue" size="lg">
              <TagLabel>{`${labelFor(key)}: ${JSON.stringify(
                value
              )}`}</TagLabel>
              <TagCloseButton onClick={() => removeFilter(key)} />
            </Tag>
          ))}
        </Flex>
      </Box>

      {/* Save and Load Queries Section */}
      <Box mb={4}>
        <Button colorScheme="blue" onClick={onOpen} mb={4}>
          Save Query
        </Button>
        <Box
          border="1px solid #ddd"
          borderRadius="md"
          p={3}
          bg="gray.50"
          shadow="sm"
        >
          <SavedQueriesLibrary
            onLoadQueries={handleLoadQueries}
            activeFilters={activeFilters}
            setLoadedQuery={setLoadedQuery}
            loadedQuery={loadedQuery}
          />
        </Box>
      </Box>

      {/* Filter Categories as Accordion */}
      <Accordion allowMultiple>
        {Object.entries(categories).map(([categoryKey, categoryLabel]) => (
          <AccordionItem key={categoryKey}>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                {categoryLabel}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Stack spacing={4}>
                {(groupedFilters[categoryKey] || []).map(
                  ({ rule, filterConfig }) => (
                    <Box
                      key={rule}
                      p={2}
                      border="1px solid #ddd"
                      borderRadius="md"
                    >
                      <DynamicFilterUI
                        filterKey={rule}
                        filterConfig={filterConfig}
                        onChange={(value) => handleFilterChange(rule, value)}
                        resetKey={resetKey}
                      />
                    </Box>
                  )
                )}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      {/* Save Query Modal */}
      {isOpen && (
        <SaveQueryModal
          isOpen={isOpen}
          onClose={onClose}
          activeFilters={activeFilters}
          queryToEdit={loadedQuery}
          onSaveSuccess={onClose}
        />
      )}

      {/* Scroll-to-Top Button */}
      <ScrollTopButton onClick={scrollToTop} />
    </Box>
  );
};

export default FiltersPanel;
