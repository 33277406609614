import React, { useState } from "react";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Checkbox,
  Stack,
  Select,
  Input,
  Text,
} from "@chakra-ui/react";
import { calculateAge } from "../utils/util";
import { getFilterConfig } from "../filterConfig"; // Ensure it's available here

const ExportModal = ({
  isOpen,
  onClose,
  patients = [], // Default to an empty array if patients are not passed
  activeFilters = [], // Default to an empty array if activeFilters are not passed
}) => {
  const [exportFormat, setExportFormat] = useState("csv");
  const [exportTitle, setExportTitle] = useState("Exported Results");
  const [selectedColumnsForExport, setSelectedColumnsForExport] = useState([]);

  // Derive selected columns based on active filters
  const selectedColumns = activeFilters
    .map((filter) => {
      const config = getFilterConfig(filter.key);
      return config?.buildFilter({}).property;
    })
    .filter(
      (column) => column && !["PatientAge", "PatientSex"].includes(column)
    );

  const handleColumnSelection = (column, isChecked) => {
    setSelectedColumnsForExport((prev) =>
      isChecked ? [...prev, column] : prev.filter((col) => col !== column)
    );
  };

  const handleExport = () => {
    if (exportFormat === "csv") {
      exportToCSV();
    } else if (exportFormat === "pdf") {
      exportToPDF();
    }
    onClose();
  };

  const generateQueryDetails = () => {
    return activeFilters
      .map((filter) => `${filter.key}: ${JSON.stringify(filter.value)}`)
      .join("\n");
  };

  const exportToCSV = () => {
    const queryDetails = generateQueryDetails();
    const headers = [
      "Title: " + exportTitle,
      "Date Created: " + new Date().toLocaleString(),
      "",
      "Query Details:",
      queryDetails,
      "",
      ["Patient ID", "Age", "Sex", ...selectedColumnsForExport].join(","),
    ];
    const rows = patients.map((patient) => [
      patient._id,
      patient.PatientAge || calculateAge(patient.PatientBirthDate),
      patient.PatientSex || "N/A",
      ...selectedColumnsForExport.map((property) => patient[property] || "N/A"),
    ]);

    const csvContent =
      headers.join("\n") +
      "\n" +
      rows.map((row) => row.map((val) => `"${val}"`).join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "export.csv");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const queryDetails = generateQueryDetails();

    // Add title and date
    doc.text(exportTitle, 10, 10);
    doc.text("Date Created: " + new Date().toLocaleString(), 10, 20);

    // Add query details
    doc.text("Query Details:", 10, 30);
    doc.text(queryDetails, 10, 40);

    // Add results table
    const headers = ["Patient ID", "Age", "Sex", ...selectedColumnsForExport];
    const rows = patients.map((patient) => [
      patient._id,
      patient.PatientAge || calculateAge(patient.PatientBirthDate),
      patient.PatientSex || "N/A",
      ...selectedColumnsForExport.map((property) => patient[property] || "N/A"),
    ]);

    doc.autoTable({
      head: [headers],
      body: rows,
      startY: 50,
    });

    doc.save("export.pdf");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Export Results</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight="bold" mb={4}>
            Enter Export Title:
          </Text>
          <Input
            value={exportTitle}
            onChange={(e) => setExportTitle(e.target.value)}
            placeholder="Enter title for export"
            mb={4}
          />

          <Text fontWeight="bold" mb={4}>
            Select Columns to Include in Export:
          </Text>
          <Stack spacing={2}>
            <Checkbox isChecked isDisabled>
              Patient ID
            </Checkbox>
            <Checkbox isChecked isDisabled>
              Age
            </Checkbox>
            <Checkbox isChecked isDisabled>
              Sex
            </Checkbox>
            {(selectedColumns || []).map((column) => (
              <Checkbox
                key={column}
                isChecked={selectedColumnsForExport.includes(column)}
                onChange={(e) =>
                  handleColumnSelection(column, e.target.checked)
                }
              >
                {column}
              </Checkbox>
            ))}
          </Stack>

          <Text fontWeight="bold" mt={4} mb={2}>
            Select Export Format:
          </Text>
          <Select
            value={exportFormat}
            onChange={(e) => setExportFormat(e.target.value)}
          >
            <option value="csv">CSV</option>
            <option value="pdf">PDF</option>
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleExport}>
            Export
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExportModal;
