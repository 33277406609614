import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers";
import throttle from "lodash/throttle";
import { persistCurrentState, supportsPersistence } from "./utils/persistence";
// import Reactotron from "./ReactotronConfig";

// Logging middleware to debug actions and state changes
const loggingMiddleware = (store) => (next) => (action) => {
  console.log("Dispatching action:", action);
  const result = next(action); // Pass the action to the next middleware/reducer
  console.log("Updated state:", store.getState()); // Log the updated state
  return result; // Return the result for the middleware chain
};

const store = configureStore({
  reducer: appReducer,
  devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in non-production environments
  //  middleware: (getDefaultMiddleware) =>
  //    getDefaultMiddleware().concat(loggingMiddleware), // Add logging middleware to the default middleware
});

// persist parts of the store whenever it changes.
if (supportsPersistence()) {
  store.subscribe(
    throttle(() => {
      persistCurrentState(store.getState(), ["bar", "settings", "favourites"]);
    }, 1000) // Throttle persistence updates to avoid performance issues
  );
}

export default store;
