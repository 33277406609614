import React, { useEffect, useMemo } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { loadDatabase } from "./utils/db.utils";
import store from "./store";

import Topbar from "./components/Topbar";
import PatientBrowser from "./components/PatientBrowser";
import PatientPage from "./components/PatientPage";
import Profile from "./components/Profile";
import Footer from "./components/Footer";
import PDFGenerator from "./components/PDFGenerator";
import AuditReport from "./components/AuditReport";
import FaxStatusPage from "./components/FaxStatusPage";
import ManageReferringPhysicians from "./components/ManageReferringPhysicians";
import ManageReportTemplates from "./components/ManageReportTemplates";
import ManageNormalValues from "./components/ManageNormalValues";
import ManageMacros from "./components/ManageMacros";
import UserMgmt from "./components/UserMgmt";
import QueryTool from "./components/QueryTool";

import "./App.css";

import { useUser } from "./UserContext";

function App({ signOut, ...args }) {
  const user = useUser();

  useEffect(() => {
    async function loadData() {
      if (!user) return;
      try {
        await loadDatabase(store); // Load database securely using the authenticated user
      } catch (err) {
        console.error("Failed to load database:", err);
      }
    }

    loadData();
  }, []);

  const memoizedArgs = useMemo(() => args, [args]);

  if (!user) {
    return <div>Loading...</div>; // Ensure `App` gracefully handles a missing user
  }

  return (
    <>
      <Router>
        <header>
          <Topbar signOut={signOut} />
        </header>
        <Switch>
          <Route exact path={"/"}>
            <PatientBrowser {...memoizedArgs} />
          </Route>
          <Route exact path={"/audit"} component={AuditReport} />
          <Route exact path={"/faxStatus"} component={FaxStatusPage} />
          <Route
            exact
            path={"/referringphysicians"}
            component={ManageReferringPhysicians}
          />
          <Route
            exact
            path={"/reporttemplates"}
            component={ManageReportTemplates}
          />
          <Route exact path={"/normalvalues"} component={ManageNormalValues} />
          <Route exact path={"/macros"} component={ManageMacros} />
          <Route path={"/report/:slug"}>
            <PDFGenerator />
          </Route>
          <Route path={"/patients/:slug"} component={PatientPage} />
          <Route path="/profile" component={Profile} />
          <Route path="/user-management" component={UserMgmt} />
          <Route path="/query-tool" component={QueryTool} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default React.memo(App);
