/**
 * Filter Config
 *
 * Stores the main filter config object and exports a bunch of
 * utility functions to help work with it. A 'filter' can exist in
 * two distinct forms;
 *
 * * An intention from the user (e.g. in their options)
 * * A filter that is ready to be applied
 *
 * In this config, the key is the "rule" and filters can have the
 * following properties;
 *
 * label: The human-readable label to display for this filter
 * dialogComponent: (optional) Dialog associated with filter
 * buildFilter: Function for constructing a filter from user options
 *
 * the `buildFilter` function of each filter config entry will be
 * passed two arguments;
 *
 * - the user filter options
 * - the rest of the state
 *
 * it should  return an object representing the filter rule to apply.
 * At the very least this needs to specify a "rule" property - this
 * will be used to determine which function to  call in `filterRules`
 * in order to actually apply the filter.
 *
 */
import { get, property } from "lodash";
import { generateOptions } from "./utils/util";

/* import {
  GlassFilterDialog,
  CategoryFilterDialog,
  DiagnosisFilterDialog,
} from "./components/Filters";
*/

/**
 * Main filter config object
 */
export const filterConfig = {
  abnormalsOnly: {
    label: "Abnormals",
    buildFilter: () => ({
      rule: "mustBeTrue",
      property: "abnTotal",
    }),
    scope: ["PatientFilter"],
    category: "clinical",
  },
  favouritesOnly: {
    label: "Favourites",
    buildFilter: (_, { favourites }) => ({
      rule: "isFavourite",
      favourites,
    }),
    scope: ["PatientFilter"],
    category: "metadata",
  },
  waitingToBeReadOnly: {
    label: "Waiting To Be Read",
    buildFilter: () => ({
      rule: "valueEquals",
      property: "studyStatus",
      value: "Waiting To Be Read",
    }),
    scope: ["PatientFilter"],
    category: "metadata",
  },
  inProgressOnly: {
    label: "In progress",
    buildFilter: () => ({
      rule: "valueEquals",
      property: "studyStatus",
      value: "In progress",
    }),
    scope: ["PatientFilter"],
    category: "metadata",
  },
  signedOnly: {
    label: "Signed",
    buildFilter: () => ({
      rule: "valueEquals",
      property: "studyStatus",
      value: "Signed",
    }),
    scope: ["PatientFilter"],
    category: "metadata",
  },
  todaysStudies: {
    label: "Today's Studies",
    buildFilter: () => ({
      rule: "todaysStudies",
      property: "SeriesDate",
    }),
    scope: ["PatientFilter"],
    category: "metadata",
  },
  freeFormStudies: {
    label: "Free Form",
    buildFilter: () => ({
      rule: "mustIncludeValue",
      property: "ProtocolName",
      value: "free",
    }),
    scope: ["PatientFilter"],
    category: "metadata",
  },
  physiologicalStressStudies: {
    label: "Physiological Stress",
    buildFilter: () => ({
      rule: "mustIncludeValue",
      property: "ProtocolName",
      value: "stress",
    }),
    scope: ["PatientFilter"],
    category: "imaging",
  },
  dobutamineStressStudies: {
    label: "Dobutamine Stress",
    buildFilter: () => ({
      rule: "mustIncludeValue",
      property: "ProtocolName",
      value: "dobutamine",
    }),
    scope: ["PatientFilter"],
    category: "imaging",
  },
  pediatricStudies: {
    label: "Pediatric",
    buildFilter: () => ({
      rule: "mustIncludeValue",
      property: "ProtocolName",
      value: "pediatric",
    }),
    scope: ["PatientFilter"],
    category: "clinical",
  },
  archivedStudies: {
    label: "Archived",
    buildFilter: () => ({
      rule: "valueEquals",
      property: "studyStatus",
      value: "Archived",
    }),
    scope: ["PatientFilter"],
    category: "metadata",
  },
  amendedStudies: {
    label: "Amended",
    buildFilter: () => ({
      rule: "valueEquals",
      property: "studyStatus",
      value: "Amended",
    }),
    scope: ["PatientFilter"],
    category: "metadata",
  },
  patientsex: {
    label: "Sex",
    buildFilter: ({ value }) => ({
      rule: "valueEquals",
      property: "PatientSex",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "demographics",
    uiType: "dropdown",
    uiProps: {
      options: ["M", "F", "O"],
    },
  },
  patientage: {
    label: "Age",
    buildFilter: ({ min, max }) => ({
      rule: "valueInRange",
      property: "PatientAge",
      value: { min, max },
    }),
    scope: ["FiltersPanel"],
    category: "demographics",
    uiType: "range",
    uiProps: { min: 0, max: 100, defaultValue: [30, 60] },
  },
  BSA: {
    label: "BSA",
    buildFilter: ({ min, max }) => ({
      rule: "valueInRange",
      property: "BSA",
      value: { min, max },
    }),
    scope: ["FiltersPanel"],
    category: "demographics",
    uiType: "range",
    uiProps: { min: 0, max: 3, defaultValue: [1, 2] },
  },
  lvefRange: {
    label: "LVEF",
    buildFilter: ({ min, max }) => ({
      rule: "valueInRange",
      property: "EFSimpsons",
      value: { min, max },
    }),
    scope: ["FiltersPanel"],
    category: "clinical",
    uiType: "range",
    uiProps: { min: 0, max: 100, defaultValue: [30, 60] },
  },
  studyDate: {
    label: "Study Date",
    buildFilter: ({ startDate, endDate }) => ({
      rule: "dateInRange",
      property: "SeriesDate",
      value: { startDate, endDate },
    }),
    scope: ["FiltersPanel"],
    category: "metadata",
    uiType: "date",
  },
  reasonForTest: {
    label: "Indication",
    buildFilter: ({ value }) => ({
      rule: "valueEquals",
      property: "reasonForTest",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "metadata",
    uiType: "dropdown",
    uiProps: {
      options: [
        "other",
        "murmur",
        "stenosis",
        "regurgitation",
        "mvp",
        "congenital",
        "prosthetic",
        "endocarditis",
        "pericardial",
        "cardiacmass",
        "interventional",
        "pulmonary",
        "chestpain",
        "cad",
        "dyspnea",
        "edema",
        "cardiomyopathy",
        "hypertension",
        "thoracic",
        "neurologic",
        "arrhythmia",
        "syncope",
        "palpitations",
        "precardio",
        "structural",
        "tee",
        "se",
      ],
    },
  },
  referringPhysician: {
    label: "Referring Physician",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "ReferringPhysicianName",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "metadata",
    uiType: "dropdown",
    uiProps: {
      options: [], // Placeholder; will be populated dynamically
      getOptions: (data) => generateOptions(data, "ReferringPhysicianName"),
    },
  },
  readingPhysician: {
    label: "Reading Physician",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "PhysiciansOfRecord",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "metadata",
    uiType: "dropdown",
    uiProps: {
      options: [], // Placeholder; will be populated dynamically
      getOptions: (data) => generateOptions(data, "PhysiciansOfRecord"),
    },
  },
  sonographer: {
    label: "Sonographer",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "OperatorsName",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "metadata",
    uiType: "dropdown",
    uiProps: {
      options: [],
      getOptions: (data) => generateOptions(data, "OperatorsName"),
    },
  },
  machine: {
    label: "Machine",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "StationName",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "metadata",
    uiType: "dropdown",
    uiProps: {
      options: [],
      getOptions: (data) => generateOptions(data, "StationName"),
    },
  },
  studyQuality: {
    label: "Study Quality",
    buildFilter: ({ value }) => ({
      rule: "valueEquals",
      property: "studyQuality",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "metadata",
    uiType: "dropdown",
    uiProps: {
      options: ["good", "adequate", "tds"],
    },
  },
  patientID: {
    label: "Patient ID",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "PatientID",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "demographics",
    uiType: "text",
  },
  conclusion: {
    label: "Conclusion",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "testConclusion",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "clinical",
    uiType: "text",
  },
  comments: {
    label: "Comments",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "Comments",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "metadata",
    uiType: "text",
  },
  LV: {
    label: "Left Ventricle",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "lvSizeAndFunction",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "clinical",
    uiType: "text",
  },
  RV: {
    label: "Right Ventricle",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "rvPaPv",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "clinical",
    uiType: "text",
  },
  Atria: {
    label: "Atria",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "laAndRa",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "clinical",
    uiType: "text",
  },
  MVTV: {
    label: "Mitral and Tricuspid Valves",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "mvAndTv",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "clinical",
    uiType: "text",
  },
  AVPV: {
    label: "Aortic and Pulmonic Valves",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "aortaAndAv",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "clinical",
    uiType: "text",
  },
  Vessels: {
    label: "Vessels and Miscellaneous",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "vessels",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "clinical",
    uiType: "text",
  },
  studyStatus: {
    label: "Study Status",
    buildFilter: ({ value }) => ({
      rule: "valueEquals",
      property: "studyStatus",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "metadata",
    uiType: "dropdown",
    uiProps: {
      options: [
        "Waiting To Be Read",
        "In progress",
        "Signed",
        "Archived",
        "Amended",
        "Test In Progress",
      ],
    },
  },
  protocol: {
    label: "Protocol",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "ProtocolName",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "imaging",
    uiType: "dropdown",
    uiProps: {
      options: ["stress", "dobutamine"],
    },
  },
  diastolic: {
    label: "Diastolic Function",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "DFA",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "clinical",
    uiType: "text",
  },
  systolic: {
    label: "Systolic Function",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "EFSimpsonsQ",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "clinical",
    uiType: "text",
  },
  contrast: {
    label: "Contrast Agent",
    buildFilter: ({ value }) => ({
      rule: "mustMatchBoolean",
      property: "isContrastAgentUsed",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "imaging",
    uiType: "checkbox",
  },
  contrastNotes: {
    label: "Contrast Agent Notes",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "contrastAgentNotes",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "imaging",
    uiType: "text",
  },
  auditUser: {
    label: "User",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "audit.user",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "audit",
    uiType: "text",
  },
  auditDate: {
    label: "Audit Date",
    buildFilter: ({ startDate, endDate }) => ({
      rule: "dateInRange",
      property: "audit.time",
      value: { startDate, endDate },
    }),
    scope: ["FiltersPanel"],
    category: "audit",
    uiType: "date",
  },
  auditAccessType: {
    label: "Access Type",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "audit.accessType",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "audit",
    uiType: "dropdown", // Custom UI for multiple conditions
    uiProps: {
      // Example: Predefined fields or dynamic options
      options: ["view", "sign", "amend", "archive", "echo", "shared"],
    },
  },
  auditRole: {
    label: "Role",
    buildFilter: ({ value }) => ({
      rule: "mustIncludeValue",
      property: "audit.role",
      value,
    }),
    scope: ["FiltersPanel"],
    category: "audit",
    uiType: "dropdown", // Custom UI for multiple conditions
    uiProps: {
      // Example: Predefined fields or dynamic options
      options: [
        "educator",
        "learner",
        "sonographer",
        "cardiologist",
        "patient",
      ],
    },
  },
  /*
  demographicInfo: {[
    "PatientBirthDate",
    "PatientID",
    "PatientSex",
    "PatientAge",
    "PatientSize",
    "PatientWeight",
  ],
  scope: ["FiltersPanel"],
},
  referringPhysician: [
    "ReferringPhysicianName",
    "ReferringPhysicianId",
    "PhysiciansOfRecord",
    "OperatorsName",
  ],
  studyDetails: [
    "StudyInstanceUID",
    "StudyDate",
    "StudyTime",
    "SeriesInstanceUID",
    "SeriesDate",
    "SeriesTime",
    "InstitutionName",
    "StationName",
    "Manufacturer",
    "ManufacturerModelName",
  ],
  pathologyAndComments: [
    "Pathology",
    "Comments",
    "testConclusion",
    "comparisonPriors",
    "comparisonPriorsOther",
    "reasonForTest",
    "reasonForTestOther",
    "studyQuality",
    "tdsReason",
    "otherTdsReason",
  ],
  diagnosticData: [
    "DFA",
    "DFASort",
    "DFADiagnosis",
    "LVM",
    "LVMSort",
    "LVMDiagnosis",
    "abnDiagnosis",
    "abnMeasure",
    "abnTotal",
    "Images",
    "Numimages",
    "oParentStudy",
    "studyStatus",
  ],
  echocardiographicData: {
    lvSizeAndFunction: [
      "LVIDd",
      "LVIDs",
      "GLS",
      "IVSd",
      "PWd",
      "StrainA4",
      "EFSimpsons",
      "StrainA3",
      "LVOTDIAM",
      "LVEDV",
      "LVEDVx",
      "COx",
      "LVESV",
      "CO",
      "LVESVx",
      "SVx",
      "StrainA2",
      "EFTeich",
      "LVMass",
      "LVMassIndex",
      "RWT",
      "SVTeich",
      "SVTeichx",
      "COTeich",
      "COTeichx",
    ],
    rvPaPv: [
      "RVIDd",
      "TAPSE",
      "S",
      "TRPG",
      "RVB",
      "RVM",
      "RVL",
      "PVVel",
      "PVPG",
      "PVVTI",
      "QPQS",
      "AVSVx",
      "AVCOx",
      "RVOT",
    ],
    laAndRa: [
      "LAd",
      "LALsA4",
      "LAAsA4",
      "LALsA2",
      "LAAsA2",
      "LAVA4",
      "LAESV",
      "LAV",
      "RAVI",
      "RAa",
      "RAV",
      "s",
      "d",
      "a",
      "adur",
      "sdratio",
      "LAVI",
      "LAVA2",
      "LAVIA4",
      "LAVIA2",
    ],
    aortaAndAv: [
      "AO",
      "AscAo",
      "LAAO",
      "AVVmax",
      "AVPG",
      "AVMG",
      "AVAVmax",
      "AVAI",
      "AVAVTI",
      "LVOTmaxPG",
      "LVOTVmax",
      "DI",
      "ARPHT",
      "AVVTI",
      "AVSV",
      "AVCO",
    ],
    mvAndTv: [
      "TRVel",
      "TRmaxPG",
      "E",
      "A",
      "DecT",
      "MVEA",
      "esep",
      "Eesep",
      "elat",
      "Eelat",
      "IVRT",
      "Ee",
      "MVPHT",
      "MVTRCE",
      "MVMRERO",
      "RASP",
      "RVSP",
      "IVC",
      "TRMV",
      "TRMG",
      "PAP",
    ],
  },
  pediatricMeasurements: [
    "pMPA",
    "pPV",
    "pMV",
    "pTV",
    "pRPA",
    "pLPA",
    "pAS",
    "pSJ",
    "pTRAR",
    "pIST",
    "pDscAo",
  ],
  stressEchoData: {
    stressEchoType: [
      "stressEchoType",
      "pharmacologicalNotes",
      "ultrasonicEnhancingAgent",
      "stressTestProtocol",
      "reasonForStopping",
      "functionalCapacity",
      "hemodynamicExerciseResponse",
    ],
    bullseyeStressEcho: [
      "basalAnterior",
      "basalAnteroseptal",
      "basalInferoseptal",
      "basalInferior",
      "basalInferolateral",
      "basalAnterolateral",
      "midAnterior",
      "midAnteroseptal",
      "midInferoseptal",
      "midInferior",
      "midInferolateral",
      "midAnterolateral",
      "apicalAnterior",
      "apicalSeptal",
      "apicalInferior",
      "apicalLateral",
      "apex",
      "wmsi",
      "postbasalAnterior",
      "postbasalAnteroseptal",
      "postbasalInferoseptal",
      "postbasalInferior",
      "postbasalInferolateral",
      "postbasalAnterolateral",
      "postmidAnterior",
      "postmidAnteroseptal",
      "postmidInferoseptal",
      "postmidInferior",
      "postmidInferolateral",
      "postmidAnterolateral",
      "postapicalAnterior",
      "postapicalSeptal",
      "postapicalInferior",
      "postapicalLateral",
      "postapex",
      "postwmsi",
    ],
    physiologicalStressEcho: [
      "baselineECG",
      "peakExerciseECG",
      "recoveryECG",
      "restingStressEcho",
      "peakExerciseStressEcho",
      "recoveryStressEcho",
      "stressEchoFindings",
    ],
    pharmacologicalStressEcho: [
      "restingECGEcho",
      "lowDoseDobutamineECGEcho",
      "peakDoseDobutamineECGEcho",
      "recoveryECGEcho",
    ],
  },
  metadata: [
    "isGoldReport",
    "LearnerID",
    "newSignedDate",
    "abnormalValues",
    "audit",
    "lastViewedByUser",
    "lastModifiedByUser",
  ],
  contrastData: [
    "isContrastAgentUsed",
    "contrastAgentNotes",
    "isBullsEyeTableDisplayed",
    "isZScoreTableDisplayed",
    "zScore",
  ],
  bloodPressureAndVitals: [
    "BPs",
    "BPd",
    "Rhythm",
    "HR",
    "otherRhythm",
  ],
  */
};

export const setupFilterConfig = (user) => {
  if (user && user?.["custom:clinicName"]?.indexOf("MK") > -1) {
    filterConfig["drAlRiyamiStudies"] = {
      label: "Dr. Al Riyami's studies",
      buildFilter: () => ({
        rule: "mustIncludeValue",
        property: "PhysiciansOfRecord",
        value: "al riyami",
      }),
      scope: ["PatientFilter"],
    };

    filterConfig["drZuberiStudies"] = {
      label: "Dr. Zuberi's studies",
      buildFilter: () => ({
        rule: "mustIncludeValue",
        property: "PhysiciansOfRecord",
        value: "zuberi",
      }),
      scope: ["PatientFilter"],
    };

    filterConfig["drLonnStudies"] = {
      label: "Dr. Lonn's studies",
      buildFilter: () => ({
        rule: "mustIncludeValue",
        property: "PhysiciansOfRecord",
        value: "lonn",
      }),
      scope: ["PatientFilter"],
    };
  }

  if (
    (user && user?.["custom:role"] === "learner") ||
    (user && user?.["custom:role"] === "educator")
  ) {
    filterConfig["goldReportStudies"] = {
      label: "Test Reports",
      buildFilter: () => ({
        rule: "mustBeTrue",
        property: "isGoldReport",
      }),
      scope: ["PatientFilter"],
    };
    filterConfig["testsInProgress"] = {
      label: "Tests In Progress",
      buildFilter: () => ({
        rule: "valueEquals",
        property: "studyStatus",
        value: "Test In Progress",
      }),
      scope: ["PatientFilter"],
    };
  }
};

/*
export function getFilterConfig(filterRule) {
  return filterConfig[filterRule && filterRule.toString()];
}
*/

export function getFilterConfig(filterRule) {
  if (!filterRule) {
    console.error("Invalid filterRule passed to getFilterConfig:", filterRule);
    return null;
  }

  const config = filterConfig[filterRule.toString()];
  if (!config) {
    console.warn(`No configuration found for filterRule: ${filterRule}`);
    return null;
  }

  return config;
}

// Fishes out the label for a filter rule (if there is one)
export function labelFor(filterRule) {
  return get(getFilterConfig(filterRule), "label") || filterRule;
}

// Fishes out the custom dialog for a filter rule (if there is one)
export function dialogFor(filterRule) {
  return get(getFilterConfig(filterRule), "dialogComponent");
}

// Returns true if this filter rule has a custom dialog
export function hasDialog(filterRule) {
  return !!dialogFor(filterRule);
}

// HoF for building the actual filters that will be applied from
// user options.
/* export function buildFilter(filterRule) {
  return get(getFilterConfig(filterRule), "buildFilter");
}
*/

export function buildFilter(filterRule) {
  const filterConfig = getFilterConfig(filterRule);
  if (!filterConfig || typeof filterConfig.buildFilter !== "function") {
    throw new Error(
      `No valid buildFilter function found for filterRule: ${filterRule}`
    );
  }
  return filterConfig.buildFilter;
}

// Returns a list of either browser or query rules
export const getRules = (scope) => {
  return Object.entries(filterConfig)
    .filter(([, config]) => config.scope.includes(scope))
    .map(([key]) => key);
};
