// import { arrayContainsArray } from "./util";
// import { getIngredientKeys } from "./cocktail.utils";

import { customDateFormat } from "./util";

/**
 * Patient filtering rules
 *
 * each rule basically needs to return 'true' if the patient should
 * be returned. Adding an export here will automatically make it
 * applyable as a filter so long as an equivalent entry exists
 * in the 'filterConfig'.
 *
 */

export const nameIncludes = (patient, { value }) => {
  if (!patient?.PatientName) {
    console.error("Patient object does not have a name property:", patient);
    return false;
  }
  return patient.PatientName.toLowerCase().includes(value.toLowerCase());
};

// patient will be returned if the passed-in property is truthy
export function mustHaveTruthyProperty(patient, { property }) {
  // console.log("truthy", !!patient[property.toString()]);
  return !!patient[property.toString()];
}

export function mustHaveStudyStatus(patient, { property, value }) {
  return patient[property.toString()] === value;
}

export function mustBeTrue(patient, { property }) {
  // console.log("true", !!patient[property]);
  return !!patient[property];
}

export function mustIncludeValue(patient, { property, value }) {
  if (!property || typeof value !== "string") {
    console.error("Invalid arguments provided to mustIncludeValue:", {
      property,
      value,
    });
    return false;
  }

  // Split the property path (e.g., "audit.accessType") into parts
  const propertyPath = property.split(".");

  // Recursive function to traverse the patient object and get the target value(s)
  const getNestedValue = (obj, path) => {
    if (!obj || path.length === 0) return null;
    const [currentKey, ...remainingPath] = path;
    if (Array.isArray(obj)) {
      // If the current level is an array, apply the function to each element
      return obj.flatMap((item) =>
        getNestedValue(item, [currentKey, ...remainingPath])
      );
    }
    return remainingPath.length === 0
      ? obj[currentKey]
      : getNestedValue(obj[currentKey], remainingPath);
  };

  // Get the values from the specified property path
  const values = getNestedValue(patient, propertyPath);

  // Flatten the result and normalize it into an array
  const flattenedValues = Array.isArray(values) ? values : [values];

  // Check for partial matches (case insensitive)
  return flattenedValues.some(
    (item) =>
      typeof item === "string" &&
      item.toLowerCase().includes(value.toLowerCase())
  );
}

export function todaysStudies(patient, { property }) {
  // customDateFormat returns a date in the form mmm-dd-yyyy, example: Dec-31-2019
  // Replace all the dashes in the returned date with spaces, example: Dec 31 2019
  const dateString = customDateFormat(patient[property]).replaceAll("-", " ");
  // Get today's date, example: Wed Apr 07 2021 19:35:01 GMT-0400 (Eastern Daylight Time)
  // Convert date to a string, find out if the dateString is a part of today's date
  return new Date().toString().includes(dateString);
}

/**
 * Generic filter rules
 *
 * These rules are designed to evaluate data based on common operations,
 * making them reusable across different filters and datasets.
 */

// Check if a numeric value is within a given range (inclusive)
export function valueInRange(item, { property, min, max }) {
  console.log("valueInRange Inputs:", { property, min, max, item });

  // Ensure min and max are valid
  if (typeof min === "undefined" || typeof max === "undefined") {
    console.error("Invalid range values:", { min, max });
    return false; // Skip the filter if range is invalid
  }

  const itemValue = item[property];
  if (itemValue === undefined || itemValue === null) {
    console.warn(`Property "${property}" is missing in item:`, item);
    return false;
  }

  // Ensure itemValue is a number (if applicable)
  const numericValue =
    typeof itemValue === "string" ? parseFloat(itemValue) : itemValue;

  if (isNaN(numericValue)) {
    console.error(
      `Invalid numeric value for property "${property}":`,
      itemValue
    );
    return false;
  }

  // Check if the value is within the range
  return numericValue >= min && numericValue <= max;
}

// Check if a string contains another string (case insensitive)
export function stringIncludes(item, { property, searchText }) {
  const value = item[property]?.toString().toLowerCase();
  return value?.includes(searchText.toLowerCase());
}

// Check if a value is equal to a given value
export function valueEquals(item, { property, value }) {
  return item[property] === value;
}

// Check if a value is truthy
export function valueIsTruthy(item, { property }) {
  return !!item[property];
}

// Check if a date is within a specified range
export function dateInRange(item, { property, startDate, endDate }) {
  const normalizeDate = (date) => {
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0);
    return normalized;
  };

  // Ensure startDate and endDate are valid dates and normalize them
  let filterStartDate = normalizeDate(startDate);
  let filterEndDate = normalizeDate(endDate);

  if (isNaN(filterStartDate)) {
    console.error("Invalid startDate:", startDate);
    filterStartDate = normalizeDate(-8640000000000000);
  }
  if (isNaN(filterEndDate)) {
    console.error("Invalid endDate:", endDate);
    filterEndDate = normalizeDate(8640000000000000);
  }

  if (Array.isArray(item[property])) {
    // Handle array of objects case
    return item[property].some((element) => {
      const seriesDateStr = element.time || element[property]; // Check 'time' field or property
      const seriesDate = new Date(seriesDateStr);
      return seriesDate >= filterStartDate && seriesDate <= filterEndDate;
    });
  }

  // Regular case
  const seriesDateStr = item[property];
  if (!seriesDateStr) {
    console.warn(`Property "${property}" is missing in item:`, item);
    return false;
  }

  const seriesDate = new Date(
    seriesDateStr.slice(0, 4), // Year
    seriesDateStr.slice(4, 6) - 1, // Month (0-based)
    seriesDateStr.slice(6, 8) // Day
  );
  if (isNaN(seriesDate)) {
    console.error("Invalid Series Date:", seriesDateStr);
    return false;
  }

  return seriesDate >= filterStartDate && seriesDate <= filterEndDate;
}

// Check if an array contains a specific value
export function arrayIncludes(item, { property, value }) {
  const array = item[property] || [];
  return Array.isArray(array) && array.includes(value);
}

// Check if a value matches a regular expression
export function matchesRegex(item, { property, regex }) {
  const value = item[property]?.toString();
  return new RegExp(regex).test(value);
}

export function auditRule(patient, { conditions }) {
  if (!Array.isArray(patient.audit)) {
    console.warn(`Audit field is not an array for patient:`, patient);
    return false;
  }

  if (!Array.isArray(conditions) || conditions.length === 0) {
    console.error("Invalid conditions for auditRule:", conditions);
    return false;
  }

  // Check if any audit entry matches all specified conditions
  return patient.audit.some((auditEntry) =>
    conditions.every(({ field, value }) => auditEntry[field] === value)
  );
}

export function mustMatchBoolean(patient, { property, value }) {
  if (typeof value !== "boolean") {
    console.error("mustMatchBoolean requires a boolean value:", { value });
    return false;
  }

  // Split property path for nested properties
  const propertyPath = property.split(".");
  const getNestedValue = (obj, path) => {
    if (!obj || path.length === 0) return null;
    const [currentKey, ...remainingPath] = path;
    if (Array.isArray(obj)) {
      return obj.some((item) =>
        getNestedValue(item, [currentKey, ...remainingPath])
      );
    }
    return remainingPath.length === 0
      ? obj[currentKey]
      : getNestedValue(obj[currentKey], remainingPath);
  };

  const targetValue = getNestedValue(patient, propertyPath);
  return targetValue === value; // Compare the property value directly
}

/*
// cocktail will be returned if it includes all of the ingredients
// in the filter - NONE can be missing.
export function makeableFrom(cocktail, { ingredients }) {
  const cocktailIngredients = getIngredientKeys(cocktail);
  if (ingredients.length === 0) return false;
  return arrayContainsArray(ingredients, cocktailIngredients);
}

// cocktail will be returned if it includes all of the
// ingredients in the filter - SOME can be missing.
export function mustInclude(cocktail, { ingredients }) {
  const cocktailIngredients = getIngredientKeys(cocktail);
  if (ingredients.length === 0) return true;
  return arrayContainsArray(cocktailIngredients, ingredients);
}

// cocktail will be returned if it contains any of the
// ingredients from the filter.
export function canInclude(cocktail, { ingredients }) {
  const cocktailIngredients = getIngredientKeys(cocktail);
  if (ingredients.length === 0) return true;
  return cocktailIngredients.some((i) => ingredients.includes(i));
}

// cocktail will be returned if it contains NONE of the ingredients
// from the filter.
export function mustNotInclude(cocktail, { ingredients }) {
  return !canInclude(cocktail, { ingredients });
}

export function inGlass(cocktail, { glasses }) {
  return glasses.length === 0 || glasses.includes(cocktail.glass);
}
*/

export function inCategory(patient, { categories }) {
  return categories.length === 0 || categories.includes(patient.category);
}

export function isFavourite(patient, { favourites }) {
  return favourites.includes(patient.slug);
}
